<template>
  <div class="check">
    <div class="check_pc">
      <img src="/assets/icon/check/banner_pc.png" />
      <div class="checkin">
        <div class="box">
          <img src="/assets/icon/check/box.png" />
          <div class="boxin">
            <div>请到持移动设备扫描二维码确认名单！</div>
            <div class="btn" @click="handlego">
              <img src="/assets/icon/check/btn_pc.png" />
              <span>回主页</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="check_m">
      <div class="top">
        <img
          src="/assets/icon/check/banner_m.png"
          v-if="!loginWallet || !holder"
        />
        <img src="/assets/icon/check/no.png" v-else />

        <div class="topin">
          <img src="/assets/icon/check/m_logo.png" />
        </div>
      </div>
      <div class="themImg" @click="connectWallet" v-if="!loginWallet">
        <img src="/assets/icon/check/wallet.png" />
      </div>
      <div class="themImg" v-else>
        <img src="/assets/icon/check/nohave.png" v-if="!holder" />
        <img src="/assets/icon/check/have.png" v-else />
      </div>
      <div class="content">
        {{ content }}
      </div>
      <div class="address" v-show="loginWallet">
        您的钱包地址为：{{ walletAddress }}
      </div>
      <div class="btn" v-if="!showSbt && loginWallet && holder">
        <img src="/assets/icon/check/btnsn.png" />
        <div class="txt">领取SBT</div>
      </div>
      <div
        class="btn"
        @click="getSbt"
        v-else-if="showSbt && loginWallet && holder"
      >
        <img src="/assets/icon/check/btns.png" />
        <div class="txt">领取SBT</div>
      </div>
      <div v-if="!showSbt && loginWallet && holder" class="getOp">
        您已经领取过了！
      </div>
    </div>
    <div class="footbanner">
      <img src="/assets/icon/check/left.png" />
    </div>
  </div>
</template>

<script>
import { connectWallet, getCurrentAccount, checkChain } from "@/utils/login.js";
import { check_have_peking_monsters } from "@/api/item/index.js";
import { redeem_activity_nft } from "@/api/trade/index.js";
import { safeMint } from "@/utils/web3.js";
export default {
  name: "Check",
  data() {
    return {
      walletAddress: "",
      currentAccounttext: "",
      content: "点击小狐狸喝酒！",
      loginWallet: false,
      holder: false,
      showSbt: false,
    };
  },
  created() {},
  mounted() {
    this.checkLogIn();
    this.watchWeb3Event();
  },

  methods: {
    async getSbt() {
      await checkChain();
      let params = {
        walletaddress: this.currentAccounttext,
        code: this.getQueryString("id"),
      };
      let data = await redeem_activity_nft(params);
      if (data.code == 200) {
        console.log(data);
        let orderInfo = data.data;
        let that = this;
        safeMint(orderInfo)
          .on("transactionHash", function (hash) {
            console.log(1111);
          })
          .on("confirmation", function (confirmationNumber, receipt) {
            console.log("confirmationNumber", confirmationNumber);
          })
          .on("receipt", function (receipt) {
            console.log(receipt, "receipt");
            that.showSbt = false;
          })
          .on("error", function (error, receipt) {
            // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
            console.log(error);
          });
      } else {
        console.log("err");
      }
    },
    handlego() {
      this.$router.push("/");
    },
    async check_have_peking_monsters() {
      console.log("this.currentAccounttext===" + this.currentAccounttext);
      let params = {
        walletaddress: this.currentAccounttext,
        code: this.getQueryString("id"),
      };
      let data = await check_have_peking_monsters(params);
      console.log(this.getQueryString("id"));
      if (data.code == 200) {
        if (data.data.have_peking_monsters == 1) {
          //持有
          this.holder = true;
          this.content = "你持有北海怪兽！快去喝酒！";
        } else {
          //无
          this.holder = false;
          this.content = "你还未持有北海怪兽路边捡捡瓶盖买一个去吧！";
        }
        if (data.data.nft_redeemed == 0) {
          this.showSbt = true;
        } else {
          this.showSbt = false;
        }
      } else {
        //无
        this.holder = false;
        this.content = "口令错误";
      }
    },
    async checkLogIn() {
      let currentAccount = await getCurrentAccount();
      if (currentAccount) {
        if (localStorage.getItem("wallet_address") != currentAccount) {
          localStorage.setItem("wallet_address", currentAccount);
        }
        this.currentAccounttext = currentAccount;
        this.loginWallet = true;
        this.walletAddress =
          currentAccount.substr(0, 4) + "***" + currentAccount.substr(-4);
        this.check_have_peking_monsters();
      } else {
        this.content = "点击小狐狸喝酒！";
      }
    },
    async connectWallet() {
      let currentAccount = await getCurrentAccount();
      if (currentAccount == null) {
        await connectWallet();
      }
      this.loginWallet = true;
    },
    watchWeb3Event() {
      var that = this;
      ethereum.on("disconnect", (res) => {
        console.log("已断开网络", res);
      });
      ethereum.on("connect", function (res) {
        console.log("已连接到网络", res);
      });
      ethereum.on("accountsChanged", function (res) {
        if (res[0]) {
          if (localStorage.getItem("wallet_address") != res[0]) {
            localStorage.setItem("wallet_address", res[0]);
          }

          that.currentAccounttext = res[0];
          that.walletAddress = res[0].substr(0, 4) + "***" + res[0].substr(-4);
          that.loginWallet = true;
          that.check_have_peking_monsters();
        } else {
          that.loginWallet = false;
          that.holder = false;
          that.content = "点击小狐狸喝酒！";
        }
      });
      ethereum.on("chainChanged", function (res) {
        const network = parseInt(res, 16);
        console.log(`networkID`, res, network);
        that.$store
          .dispatch("web3/set_network", network)
          .then((res) => {
            if (
              network == process.env.VUE_APP_MAIN_NETWORK ||
              network == process.env.VUE_APP_MAIN_NETWORK2
            ) {
            } else {
            }
          })
          .catch((err) => {});
      });
      ethereum.on("message", (msg) => console.log(msg, 11111));
    },
    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
  },
  computed: {},
};
</script>

<style src="./index.scss" lang="scss" scoped></style>


<style src="./index.mobile.scss" lang="scss" scoped></style>
