import request from '@/utils/request'
import baseURL from '@/utils/baseURL'

// 质押列表
export function list_my_item(query) {
  return request({
    url: baseURL["item"] + `/v2/item/list_my_item`,
    method: "get",
    params: query,
  });
}

// 取回列表
export function my_staked_peking_monster_list(query) {
  return request({
    url: baseURL["item"] + `/v2/peking_monsters/my_staked_peking_monster_list`,
    method: "get",
    params: query,
  });
}

// 我的质押信息
export function my_peking_monsters_profile() {
    return request({
      url: baseURL["item"] + `/v2/account/my_peking_monsters_profile`,
      method: "get",
    });
  }

// 获取全部未质押北海ID
export function list_all_peking_monsters(query) {
    return request({
      url: baseURL["item"] + `/v2/item/list_all_peking_monsters`,
      method: "get",
      params: query,
    });
  }

// 北海线下活动
export function check_have_peking_monsters(query) {
  return request({
    url: baseURL["item"] + `/v2/peking_monsters/activity_register`,
    method: "get",
    params: query,
  });
}
// 北海线下活动领取瓶盖
export function activity_redeem_beer_caps(query) {
  return request({
    url: baseURL["item"] + `/v2/peking_monsters/activity_redeem_beer_caps`,
    method: "get",
    params: query,
  });
}

//北海全部nft
export function list_all_my_peking_monsters(query) {
  return request({
    url: baseURL["item"] + `/v2/item/list_all_my_peking_monsters`,
    method: "get",
    // data
    params: query,
  });
}
